<template>
  <div class="wa__client__register">
    <v-container class="py-1 d-none d-md-block">
      <div>
        <SocialIcon />
      </div>
    </v-container>

    <Header />

    <main class="py-8">
      <div class="social__divider__wrapper">
        <SocialDivider :title="$_t('title')" sub-title="liliana" />
      </div>

      <v-container>
        <list class="wa__large" style="min-height: 600px">
          <div class="pending__box">
            <div class="d-flex justify-center mb-4">
              <i class="big__icon WMi-asterisk"></i>
            </div>

            <div class="pl-0 pl-md-9">
              <div class="title" v-html="$_t('requestSend')"></div>

              <div class="text-left text">
                <div class="wa__f__m__r">{{ $_tc("text", 1) }}</div>

                <div class="wa__f__m__r">
                  {{ $_tc("text", 2) }}
                </div>
              </div>

              <div class="text-center mt-3">
                <div class="w-75 mx-auto">
                  <v-form @submit.prevent="sendCode">
                    <v-text-field
                      outlined
                      :label="$_t('code')"
                      class="client__register no-error-msg mb-3"
                      dense
                      color="SonicSilver"
                      v-model="form.code"
                    ></v-text-field>

                    <block-button
                      :text="$_t('approve')"
                      height="30"
                      text-mode="text"
                      icon="WMi-check-linear"
                      size="large"
                      class="site__button mt-1"
                      type="submit"
                    ></block-button>
                  </v-form>
                </div>
              </div>
            </div>
          </div>
        </list>
      </v-container>
    </main>

    <footer>
      <div class="wa__home--footer">
        <site-footer :text="$_t('footerText')"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import SocialDivider from "../../components/Global/Section/SocialDivider.vue";
import Header from "../../components/Global/Header/User/Header.vue";
import SocialIcon from "../../components/Global/Header/User/SocialIcon.vue";
import { mapActions } from "vuex";
import JobOfferRepository from "../../abstraction/repository/jobOfferRepository";
export default {
  components: {
    SocialDivider,
    Header,
    SocialIcon,
  },
  data() {
    return {
      form: {
        uniqueId: this.$route.params.uniqueId,
      },
    };
  },
  methods: {
    ...mapActions("auth", ["loginWithJobOfferCode"]),
    async sendCode() {
      const response = await this.loginWithJobOfferCode(this.form);
      if (response.token) {
        this.$router.push({
          name: "modelSelections",
          params: {
            locale: this.$_getlocaleParam(),
            uniqueId: this.form.uniqueId,
          },
        });
      }
    },
    async hasUniqueId() {
      try {
        let jobOfferRepository = new JobOfferRepository({ errorHandle: false });
        await jobOfferRepository.hasJobOfferLink(this.form.uniqueId);
      } catch (error) {
        this.$router.push({
          name: "dashboard",
          params: { locale: this.$_getlocaleParam() },
        });
      }
    },
  },
  created() {
    this.hasUniqueId();
  },
};
</script>
<style scoped>
.wa__client__register {
  background-color: #f5f3f3 !important;
}

.pending__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.big__icon {
  font-size: 97px;
  color: var(--color-black);
}

.social__divider__wrapper {
  width: max-content;
  margin: 30px auto 0 auto;
}
</style>
